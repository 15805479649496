<template>
	<div id="service-team">
		<el-button size="small" type="primary" style="margin:20px 0 60px 10px;" @click="$router.push('/serverTeam')">返回</el-button>
		<el-form ref="teamForm" :model="teamForm" label-width="180px" :rules="rules">
			<el-form-item label="服务团队" prop="images">
				<el-upload
					class="avatar-uploader"
					:action="this.URL.upLoad"
					:show-file-list="false"
					:on-success="uploadSuccess"
					:before-upload="beforeAvatarUpload"
					:headers="uploadHeader"
					accept="image/jpeg, image/jpg, image/png"
				>
					<img v-if="teamForm.images" :src="teamForm.images" class="avatar" />
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="tips">(最多上传20张图片)</div>
				<div class="tips">建议上传大小为1.5：1的图片</div>
				<div class="tips">仅支持jpg、jpeg、png格式大小不能大于4M</div>
			</el-form-item>
			<el-row>
				<el-col :span="9">
					<el-form-item label="姓名" prop="name"><el-input v-model="teamForm.name"></el-input></el-form-item>
					<el-form-item label="职位" prop="position"><el-input v-model="teamForm.position"></el-input></el-form-item>
					<el-form-item label="手机" prop="mobile"><el-input v-model="teamForm.mobile"></el-input></el-form-item>					
					<el-form-item label="专业公共技术服务平台" prop="is_platform">
						 <el-radio-group v-model="teamForm.is_platform">
						    <el-radio :label="1">是</el-radio>
						    <el-radio :label="0">否</el-radio>
						  </el-radio-group>
					</el-form-item>
					<el-form-item label="品牌" prop="brand"><el-input v-model="teamForm.brand"></el-input></el-form-item>
				</el-col>
				<el-col :span="9">
					<el-form-item label="行业领域">
            <el-select
              v-model="teamForm.industry_type"
              multiple
              collapse-tags
							style="width: 100%"
              placeholder="请选择">
              <el-option
                v-for="item in options_start"
                :key="item.id"
                :label="item.title"
                :value="item.id">
              </el-option>
            </el-select>						
					</el-form-item>
					<el-form-item label="主要职责">
						<el-input style="width: 100%;" type="textarea" v-model="teamForm.rights" maxlength="40" show-word-limit rows="7"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item>
				<el-button  type="primary" @click="submitForm('teamForm')">保存</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
import { success, error } from '@/utils/notification';
export default {
	name: 'serviceTeam',
	props: {
		id: {}
	},
	data() {
		return {
			industryLabels: [], //领域数据
			uploadLogoAddress: this.URL.adminUrl.upLoad,
			uploadHeader: {},
			teamForm: {
				mobile: '',				
				name: '',
				images: '',
				position: '',
				brand: '',
				industry_type: [],
				rights: '',
				is_platform:'',
				start_id: []	//创投行业领域编号				
			}, //表单数据
			options_start: [], //级联分类领域-创投
			rules: {
				images: [{ required: true, message: '请选择服务团队', trigger: 'change' }],
				name: [
					{ required: true, message: '姓名不能为空！', trigger: 'blur' },
					{
						min: 1,
						max: 10,
						message: '长度在 1 到 10 个字符',
						trigger: 'change'
					}
				],
				mobile: [
					{ required: true, message: '请输入手机号码', trigger: 'blur' },
				],				
				position: [
					{ required: true, message: '请输入职位名称', trigger: 'blur' },
					{
						min: 1,
						max: 10,
						message: '长度在 1 到 10 个字符',
						trigger: 'change'
					}
				],
				is_platform:[{required: true, message: '请选择专业公共技术服务平台', trigger: 'change'}],
				brand: [
					{ required: true, message: '请输入品牌名称', trigger: 'blur' },
					{
						min: 1,
						max: 10,
						message: '长度在 1 到 10 个字符',
						trigger: 'change'
					}
				]
			}
		};
	},
	created() {
		this.getCategories("starts");
		this.getIndustry();
		this.id && this.getTeams();
		let token = sessionStorage.adminToken; // pc前台token
		this.uploadHeader.Authorization = 'Bearer ' + token;
	},
	methods: {
		//团队新增加和编辑
		submitForm(teamForm) {
			let images = this.teamForm.images;
			if (images.length === 0) {
				error('至少上传一张图片');
				return false;
			}
			this.$refs[teamForm].validate(valid => {
				if (valid) {
					let organ_id = Number(sessionStorage.organizationId);
					if (!this.id) {
						this.$http
							.post(this.URL.adminUrl.addTeam, {
								organ_id: organ_id,
								images: this.teamForm.images,
								mobile: this.teamForm.mobile,								
								brand: this.teamForm.brand,
								position: this.teamForm.position,
								rights: this.teamForm.rights,
								name: this.teamForm.name,
								industry_type: this.teamForm.industry_type,
								is_platform:this.teamForm.is_platform
							})
							.then(res => {
								if (res.data.code == 200) {
									success('团队新增成功');
									this.$router.push('/serverTeam');
								}
							});
					} else {
						this.$http
							.put(this.URL.adminUrl.delTeam + this.id, {
								organ_id: organ_id,
								images: this.teamForm.images,
								mobile: this.teamForm.mobile,								
								brand: this.teamForm.brand,
								position: this.teamForm.position,
								rights: this.teamForm.rights,
								name: this.teamForm.name,
								industry_type: this.teamForm.industry_type,
								is_platform:this.teamForm.is_platform
							})
							.then(res => {
								if (res.data.code == 200) {
									success('团队编辑成功');
									this.$router.push('/serverTeam');
								}
							});
					}
				} else {
					error('请检查必填项是否填写完整');
					return false;
				}
			});
		},
		//团队服务详情
		getTeams() {
			this.$http.get(this.URL.adminUrl.delTeam + this.id, {}).then(res => {
				this.teamForm = res.data.data;
			});
		},
		//获取领域数据
		getIndustry() {
			this.$http.get(this.URL.industry, {}).then(res => {
				this.industryLabels = res.data.data;
			});
		},
		//图片上传前的事件
		beforeAvatarUpload(file) {
			var testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
			const isLt4M = file.size / 1024 / 1024 <= 4; //图片大小不超过4MB
			if (!testmsg) {
				error('上传图片格式不对!');
				return;
			}
			if (!isLt4M) {
				error('上传图片大小不能超过 4M!');
				return;
			}
			return testmsg && isLt4M;
		},

		//图片上传成功事件
		uploadSuccess(res) {
			this.teamForm.images = res.data.path;
		},
    //分类 - 创投和国民行业列表
    async getCategories(url) {
      const res = await this.$http.get(this.URL.adminUrl[url], {});
			url == "economics"?this.options_economic = res.data.data:this.options_start = res.data.data
		},		
	}
};
</script>
<style lang="less">
#service-team {
	.avatar-uploader .el-upload {
		border: 2px dashed #d9d9d9;
		border-radius: 20px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 100px;
		height: 100px;
		line-height: 100px;
		text-align: center;
	}
	.avatar {
		width: 100px;
		height: 100px;
		display: block;
	}
}
</style>
